import { graphql, Link } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from '../styles/home.module.css'

export default function Home({ data }) {
  console.log(data);

  return (
    <Layout>
      <section className={styles.header}>
        <div>
          <h2 >Design</h2>
          <h3>Develop & Play</h3>
          <p>UX designer & web developer based in Manchester.</p>
          <Link className={styles.btn} to="/projects">My Portfolio Projects</Link>
        </div>
        <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="wow" />
      </section>
    </Layout>
    )
}


export const query = graphql`
query Banner {
  file(relativePath: {eq: "banner.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}


`